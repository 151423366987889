import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Flex, Text, rem, Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAtom } from "jotai";

import { Loader } from "components";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import { updatestate } from "store/reducer";
import { totalAmount } from "store/atoms";

import {
  useGetEventDetailsQuery,
  useGetBookingInfoQuery,
} from "store/services/ticketServices";
import { REDUX_STATE } from "constants";

import {
  LocationIcon,
  TimerIcon,
  ShareIcon,
  CheckIcon,
  ChevronsIconLeft,
} from "assets/icon";
import useStyles from "views/TicketServices/styles";

function OrderConfirmation() {
  const { eventName, refId, bookingId } = useParams();

  const { data: eventData, isFetching: isFetchingEventData } =
    useGetEventDetailsQuery({ name: eventName, refId });
  const { data: bookingInfo, isFetching: isFetchingBookingInfo } =
    useGetBookingInfoQuery(bookingId);

  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, setTotalPrice] = useAtom(totalAmount);
  console.log("Event data through API", eventData);

  // console.log("bookingconfirmed...", bookingconfirmed);

  useEffect(() => {
    dispatch(
      updatestate({
        reservationId: undefined,
        selectedPaymentMethod: null,
        idealBank: null,
        selectedEventPricing: null,
        addonsPricingList: null,
      })
    );

    let reduxstateFromStorage = window.localStorage.getItem(REDUX_STATE);
    reduxstateFromStorage = reduxstateFromStorage
      ? JSON.parse(reduxstateFromStorage)
      : {};

    delete reduxstateFromStorage.reservationId;
    delete reduxstateFromStorage.selectedPaymentMethod;
    if (reduxstateFromStorage.idealBank) {
      delete reduxstateFromStorage.idealBank;
    }
    delete reduxstateFromStorage.selectedEventPricing;
    delete reduxstateFromStorage.addonsPricingList;

    window.localStorage.setItem(
      REDUX_STATE,
      JSON.stringify(reduxstateFromStorage)
    );

    setTotalPrice(0);
  }, [dispatch]);

  const onBack = () => {
    navigate(`/${eventName}/${refId}`, { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    window.onpopstate = () => {
      onBack();
      return;
    };
  }, []);

  if (isFetchingEventData || isFetchingBookingInfo) {
    return (
      <div className="ticket-view-loading">
        <Flex direction="column" align="center" justify="center" gap={20}>
          <Loader size={40} />
          <Text align="center" fz="sm">
            Getting your booking confirmation...
          </Text>
        </Flex>
      </div>
    );
  }

  return (
    <div className="ticket-booking">
      <div className="order-confirmation">
        <div className="wrapper">
          <div className="page-hdr">
            <button className="back-btn" onClick={onBack}>
              <ChevronsIconLeft />
              <div className="lbl">Back</div>
            </button>
            <div className="title">Order Confirmation</div>
          </div>
          <div className="content">
            {/* Ticket Info*/}
            <EventInfoCard
              reduxstate={{
                eventData: eventData?.data,
                location: eventData?.data?.location,
                eventDate: eventData?.data?.eventDate,
                eventEndDate: eventData?.data?.eventEndDate,
              }}
              selectedStep={5}
              isReadMore={false}
              toggleReadMore={() => {}}
            />
            <div className="section payment-due">
              {bookingInfo?.data?.amount === 0 ? (
                <div className="title-free">Free Event</div>
              ) : bookingInfo?.data?.paymentStatus === "Unpaid" ? (
                <div className="title">Payments Due</div>
              ) : (
                <div className="title">
                  Payment Confirmed
                  <div className="icon">
                    <CheckIcon />
                  </div>
                </div>
              )}
              <div className="ticks">
                <Box
                  px={rem(8)}
                  py={rem(4)}
                  bg="grape.9"
                  sx={{ borderRadius: rem(10) }}
                >
                  <Text fz={14} fw={600} c="white">
                    Tickets
                  </Text>
                </Box>
                {bookingInfo?.data?.ticketsBought?.map((item, index) => {
                  // const absorbServiceFee = item?.absorbServiceFee || false;

                  return (
                    <Flex
                      key={`${index}-ticket`}
                      direction="row"
                      justify="space-between"
                      align="center"
                      my={4}
                    >
                      <Flex direction="column">
                        <Text fz={14} fw={600}>
                          {item?.ticketType}
                        </Text>
                        <Text fz={13} fw={400} c="gray">
                          {item?.bookingQty} X €
                          {parseFloat(item.ticketAmount) +
                            (item.absorbServiceFee
                              ? 0
                              : parseFloat(
                                  parseFloat(item.serviceFee) /
                                    parseFloat(item.bookingQty)
                                ))}
                        </Text>
                      </Flex>
                      <Flex direction="column">
                        <Text fz={14} fw={600} align="right">
                          €{item?.totalPrice.toFixed(2)}
                        </Text>
                      </Flex>
                    </Flex>
                  );
                })}
                {bookingInfo?.data?.addonsBought?.length > 0 && (
                  <div>
                    <Box
                      mt={rem(16)}
                      px={rem(8)}
                      py={rem(4)}
                      bg="grape.9"
                      sx={{ borderRadius: rem(10) }}
                    >
                      <Text fz={14} fw={600} c="white">
                        Add-ons
                      </Text>
                    </Box>
                    {bookingInfo?.data?.addonsBought?.map((item, index) => {
                      return (
                        <Flex
                          key={`${index}-addon`}
                          direction="row"
                          justify="space-between"
                          align="center"
                          my={4}
                        >
                          <Flex direction="column">
                            <Text fz={14} fw={600}>
                              {item?.addonName}
                            </Text>
                            <Text fz={13} fw={400} c="gray">
                              {item?.bookingQty} X €
                              {item?.ticketAmount +
                                (item?.serviceFee ? item?.serviceFee : 0)}
                            </Text>
                          </Flex>
                          <Flex direction="column">
                            <Text fz={14} fw={600} align="right">
                              €{item?.totalPrice.toFixed(2)}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </div>
                )}
                <Flex
                  justify="space-between"
                  align="center"
                  direction="row"
                  mt={rem(16)}
                >
                  <Text fz={rem(15)} fw="600">
                    Processing fee
                  </Text>
                  <Text fz={rem(15)} fw="600">
                    €{bookingInfo?.data?.totalPaymentServiceFee}
                  </Text>
                </Flex>
              </div>
              <div className="total">
                <div className="text">{`Total (incl. tax & €${bookingInfo?.data?.totalPaymentServiceFee} processing Fee)`}</div>
                <div className="price">
                  €{Number(bookingInfo?.data?.totalStripeAmount).toFixed(2)}
                </div>
              </div>
              {bookingInfo?.data?.paymentStatus === "Unpaid" && (
                <div className="notice">
                  Host may send you a payment request before start of the event
                </div>
              )}
            </div>
            <div className="section success-blk">
              <img src="/images/success.svg" className="vector" />
              <div className="label">Your Order is Confirmed!!</div>
              <div className="text">Your Booking Details are sent to</div>
              <div className="address">
                {bookingInfo?.data?.student_detail?.email}
              </div>
            </div>
            <div className="section block">
              <Button
                className={classes.submit}
                variant="filled"
                fullWidth
                // onClick={handleRestart}
                component="a"
                href={`${eventData?.data?.bookingLink}`}
                target="_blank"
                size="lg"
                fz="sm"
              >
                Book Again
              </Button>
            </div>
            {/* <div className="section share-blk">
            <button className="button-primary">
              Share <ShareIcon />
            </button>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmation;
