import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button, Flex, rem, Text } from "@mantine/core";
import { useAtom } from "jotai";

import { ErrorAlert, UserDetails } from "components";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import { ChevronsIconLeft, TikkieIcon } from "assets/icon";

import { serviceStep } from "store/atoms";
import { useCreateTikkiePaymentLinkMutation } from "store/services/ticketServices";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import { createBookingBody } from "core/methods";
import { updatestate } from "store/reducer";

export default function TikkiePayment() {
  const navigate = useNavigate();
  const reduxstate = useSelector((state) => state.State);
  console.log("Redux State", reduxstate);
  const dispatch = useDispatch();

  const [, setServiceStep] = useAtom(serviceStep);

  const [
    generateLink,
    {
      isLoading: isGeneratingLink,
      isSuccess: hasLinkGenerated,
      data: generatedLinkData,
    },
  ] = useCreateTikkiePaymentLinkMutation();
  console.log("generated link data", generatedLinkData);

  const handleGenerateLink = async () => {
    try {
      const eventBookingPayload = createBookingBody({
        ...reduxstate.addParticipantData,
        paymentStatus: "Paid",
        paymentMethod: "tikkie",
        paymentMode: "Online",
      });

      const { data, statusCode } = await generateLink({
        eventScheduleId: reduxstate.id,
        amount: Number(reduxstate?.totalPrice),
        currency: "eur",
        isPackage: false,
        selectedEventPricing: reduxstate.selectedEventPricing
          .filter((item) => item.bookingQty && item.bookingQty > 0)
          .map(({ bookingQty, totalPrice, ticketType }) => ({
            bookingQty,
            totalPrice,
            ticketType,
          })),
        eventBookingPayload,
      }).unwrap();

      if (statusCode !== 200) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ErrorAlert
                onClose={() => {
                  onClose();
                  handleChangePaymentMethod();
                }}
                error="An error occurred while generating payment link. Please try again later."
              />
            );
          },
          closeOnClickOutside: false,
          closeOnEscape: false,
        });
        return;
      }

      dispatch(updatestate({ tikkiePayLink: data?.url }));
      window.open(data?.url, "_blank")?.focus();

      navigate(`/tikkie-pay-check/${data?.paymentRequestToken}`);
    } catch (error) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ErrorAlert
              onClose={() => {
                onClose();
                handleChangePaymentMethod();
              }}
              error="An error occurred while generating payment link. Please try again later."
              isServerError={true}
            />
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false,
      });
    }
  };

  const handleChangePaymentMethod = () => {
    setServiceStep(4);
    navigate(-1, { replace: true });
  };

  return (
    <div className="ticket-services">
      <div className="wrapper">
        <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">Back</div>
          </button>
          <div className="title">Review & Pay</div>
        </div>
        <div className="content">
          <Flex direction="column">
            <EventInfoCard
              reduxstate={reduxstate}
              //   serviceData={reduxstate.serviceData}
              //   resource={reduxstate.resourceDetail}
              isReadMore={false}
              selectedStep={5}
              toggleReadMore={() => {}}
              when={{
                day: reduxstate?.bookServiceBody?.slot_date,
                start: reduxstate?.bookServiceBody?.slot_start_time,
                end: reduxstate?.bookServiceBody?.slot_end_time,
              }}
              where={reduxstate.serviceSelectedLocation}
              slotData={reduxstate.slotData}
            />
            <UserDetails />
            <ServiceBuyingSummary reduxstate={reduxstate} />
            <div className="section">
              <div className="block">
                <Flex direction="row" justify="space-between" align="center">
                  <Flex direction="row" gap="sm" align="center">
                    <Flex w={rem(36)} h={rem(36)}>
                      <TikkieIcon />
                    </Flex>
                    <Text fw={600} fz="lg">
                      Tikkie
                    </Text>
                  </Flex>
                  <Button
                    variant="subtle"
                    onClick={handleChangePaymentMethod}
                    fz="md"
                    c="#584CF4"
                  >
                    Change
                  </Button>
                </Flex>
              </div>
            </div>
            <Button
              fullWidth
              radius={10}
              bg="#f64e60"
              size="lg"
              fz="md"
              onClick={handleGenerateLink}
              sx={{
                "&:hover": {
                  backgroundColor: "#f64e60ee",
                  transition: "background-color 0.2s ease-in-out",
                },
              }}
              loading={isGeneratingLink || hasLinkGenerated}
            >
              Pay & Confirm booking
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  );
}
