import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Flex, Text } from "@mantine/core";

import OrderConfirmation from "views/OrderConfirmation";
import { Loader } from "components";

import {
  addParticipantToEvent,
  checkTikkiePayStatus,
  sendConfirmationEmail,
} from "core/methods";
import {
  ADD_PARTICIPANT_BODY,
  BOOKING_ID,
  SEND_EMAIL_CONFIRMATION_DATA,
  SERVICE_ID,
  SLOT_ID,
  TOTAL_PRICE,
} from "../../constants";
import { useSelector } from "react-redux";

export default function TikkiePaymentVerification() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  //const [reduxState, setReduxState] = useState({});
  const [addParticipantresponse, setAddParticipantresponse] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const [loadingText, setLoadingText] = useState("Confirming your payment...");
  const utcOffset = new Date().getTimezoneOffset();
  const [apiData, setApiData] = useState({});
  const reduxState = useSelector((state) => state.State);

  const sendEmailConfirmationData = window.localStorage.getItem(
    SEND_EMAIL_CONFIRMATION_DATA
  );

  let reduxstate = {}; // Default to an empty object
  if (sendEmailConfirmationData) {
    try {
      reduxstate = JSON.parse(sendEmailConfirmationData).reduxstate || {};
    } catch (error) {
      console.error("Error parsing SEND_EMAIL_CONFIRMATION_DATA", error);
    }
  }
  console.log("Reduxstate", reduxstate);

  const fetchPaymentStatus = async () => {
    const [isSuccess, data, statusCode] = await checkTikkiePayStatus({
      paymentRequestToken: id,
    });
    console.log("data from checkTikkiePayStatus", data);
    console.log("Is Success", isSuccess);

    if (isSuccess && data.paymentStatus === "Paid") {
      setLoadingText("Adding your booking...");
      window.localStorage.removeItem(ADD_PARTICIPANT_BODY);
      window.localStorage.removeItem(BOOKING_ID);
      window.localStorage.removeItem(SERVICE_ID);
      window.localStorage.removeItem(SLOT_ID);
      const totalAmount = window.localStorage.getItem(TOTAL_PRICE);
      setTotalPrice(Number(totalAmount));
      setIsLoading(false);
      setIsBookingComplete(true);
      setApiData(data);
    } else if (isSuccess && data.paymentStatus !== "Paid") {
      setTimeout(() => {
        fetchPaymentStatus(); // Recursive call to keep checking the status
      }, 2000);
    } else {
      console.error("Failed to fetch payment status");
    }
  };

  // const fetchPaymentStatus = async () => {
  //   const [isSuccess, data] = await checkTikkiePayStatus({
  //     paymentRequestToken: id,
  //   });

  //   if (isSuccess) {
  //     if (data?.numberOfPayments > 0) {
  //       setLoadingText("Adding your booking...");

  //       await handleAddParticipant().then(() => {
  //         const totalAmount = window.localStorage.getItem(TOTAL_PRICE);
  //         setTotalPrice(Number(totalAmount));
  //         setIsLoading(false);
  //       });
  //     } else {
  //       setTimeout(() => {
  //         fetchPaymentStatus();
  //       }, 2000);
  //     }
  //   }
  // };

  const handleSendEmailConfirmation = async (apr) => {
    const sendEmailConfirmationData = window.localStorage.getItem(
      SEND_EMAIL_CONFIRMATION_DATA
    );
    const { ticketFields, eventDetails, reduxstate } = JSON.parse(
      sendEmailConfirmationData
    );
    // setReduxState(reduxstate);
    const body = {
      ticketFields,
      eventDetails,
      addParticipantresponse: apr,
      reduxstate,
    };

    const [isSuccess] = await sendConfirmationEmail(body);

    if (isSuccess) {
      window.localStorage.removeItem(SEND_EMAIL_CONFIRMATION_DATA);
    } else {
      throw new Error();
    }
  };

  const handleAddParticipant = async () => {
    if (!isBookingComplete) {
      const addParticipantBody =
        window.localStorage.getItem(ADD_PARTICIPANT_BODY);

      const [isSuccess, data] = await addParticipantToEvent({
        ...JSON.parse(addParticipantBody),
        bookingId: window.localStorage.getItem(BOOKING_ID),
        utcOffset,
      });

      if (isSuccess) {
        await handleSendEmailConfirmation(data).then(() => {
          setAddParticipantresponse(data);
        });
        window.localStorage.removeItem(ADD_PARTICIPANT_BODY);
        window.localStorage.removeItem(BOOKING_ID);
        setIsBookingComplete(true);
      } else {
        throw new Error();
      }
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log("API Data", apiData);

  if (isLoading) {
    return (
      <div className="ticket-view-loading">
        <Flex direction="column" gap={20} align="center" justify="center">
          <Loader size={40} />
          <Text align="center" fz="sm">
            {loadingText}
          </Text>
          {/* </Flex>
        <Flex justify="center" align="center" direction="column" gap={4}> */}
          <Text fz="xs" fw={400} color="gray">
            Tikkie did not open?
          </Text>
          <Text
            fz="xs"
            fw={400}
            underline
            color="blue"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={reduxState.tikkiePayLink}
          >
            Click here
          </Text>
        </Flex>
      </div>
    );
  } else {
    return (
      <Navigate
        to={`/order-confirmation/${reduxstate.eventData.eventName
          .replace(/\s+/g, "-")
          .toLowerCase()}/${reduxstate.eventData.referenceId}/${
          apiData.bookingId
        }`}
        replace={true}
      />
    );
  }
}
